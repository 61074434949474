var jQuery = jQuery || null;

(function($) {
  Drupal.behaviors.LBO = {
    attach: function(context, settings) {
      //
      // Format LBO message
      //
      var orderCntr = false;
      var decodeHtmlEntities = function(elem) {
        if (elem.length) {
          var formattedStr = elem.html().replace(/&nbsp;/g, " ");
          elem.html(formattedStr);
        }
      };
      //
      // Align banner content
      //
      function alignBannerContent() {
        var $lboCont = $(".site-content .js-lbo", context);
        var $lboBanner = $lboCont.find(".js-lbo-sub-container");
        var $lboBannerImg = $lboCont.find(".js-lbo-container-image");
        var maxHeight = Math.max($lboBanner.outerHeight(), $lboBannerImg.height());
        $lboCont.height(maxHeight);
        $lboBannerImg.on("load", function(){
          alignBannerContent();
        });
      }
      //
      // On cart update, strip the &nbsp; from offer message
      //
      $(window).on("checkout:panel:displayed", function() {
        var $jsLboMessageContainer = $(".js-lbo-message-container", context);
        decodeHtmlEntities($jsLboMessageContainer);
        var $lboHolder = $(".js-lbo-message-holder", context),
            $lboMsgCont = $(".js-lbo-message-copy-content", context);
        decodeHtmlEntities($lboHolder);
        $lboMsgCont.html($lboHolder.html());

        if (!orderCntr) {
          // Reload order summary panel to update values after offer has been applied
          var $orderSummaryPanel = $(".js-order-summary-panel", context);
          var $shipMethod = $orderSummaryPanel.find('select[name="SHIP_METHOD"]');
          if ($shipMethod.length) {
            $shipMethod.change();
          }
          orderCntr = true;
        }

      });
      //
      // Show LBO banner and messages when landing with URL
      // Cookie based manipulation
      //
      if (typeof $.cookie === "function") {
        if ($.cookie("offer_info") !== "" && $.cookie("offer_info") !== null) {
          var offer_code = $.cookie("offer_info").split(":")[1];
          generic.jsonrpc.fetch({
            method: "offer.linkedOfferState",
            params: [offer_code],
            onSuccess: function(r) {
              var offer = r.getValue();
              var isActive = (offer.linked_offer !== null && offer.linked_offer.length !== 0),
                  templateResult = null;
              if (isActive) {
                var htmlStr = offer.linked_offer.cms_content,
                    $body = $("body");
                $(htmlStr).appendTo($body);
                var $confirmMessage = $(".js-lbo-message-content", context),
                    $lboHeaderCont = $(".js-lbo-header-container", context),
                    $lboMessagecontainer = $body.find(".js-lbo-message-container"),
                    $pageHeader = null,
                    lboWrapper = null;
                $confirmMessage.html(offer.linked_offer.offer_message);
                decodeHtmlEntities($confirmMessage, context);
                templateResult = $lboHeaderCont.html();

                // If offer was not configured in offer manager/CMS
                // then skip further processing
                if (typeof templateResult === "undefined") {
                  return false;
                }

                // If cart is the landing page, then don't show LBO banner in the header
                if ($lboMessagecontainer.length) return;

                $pageHeader = $body.find(".site-content");
                lboWrapper = $("<div/>");
                lboWrapper.append(templateResult);
                $pageHeader.prepend(lboWrapper);
                lboWrapper.find(".js-lbo .js-lbo-banner-close").on("click", function() {
                  $(this).closest(".js-lbo").addClass("hidden");
                });
                alignBannerContent();
              }
            },
            onFailure: function() {}
          });
        }
      }
      function onload() {
        var $jsLboMessageContainer = $(".js-lbo-message-container", context);
        decodeHtmlEntities($jsLboMessageContainer);
        alignBannerContent();
      }
      onload();
    }
  };
})(jQuery);
